import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['discount', 'spotPriceDays']

  toggleSpotPriceFields(event) {
    if (event.srcElement.checked) {
      this.spotPriceDaysTarget.classList.remove('d-none');
      this.spotPriceDaysTarget.classList.add('d-block');

      this.discountTarget.classList.remove('d-block');
      this.discountTarget.classList.add('d-none');
    } else {
      this.spotPriceDaysTarget.classList.remove('d-block');
      this.spotPriceDaysTarget.classList.add('d-none');

      this.discountTarget.classList.remove('d-none');
      this.discountTarget.classList.add('d-block');
    }
  }
}
