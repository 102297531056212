import { Controller } from '@hotwired/stimulus'

import { getMetaValue, numberWithPrecision, requestApi } from './helpers'

export default class extends Controller {
  static targets = [
    'flagType', 'freightType', 'freightCost', 'gasStation', 'imageDiscount',
    'parent', 'product', 'supplier'
  ]

  connect() {
    jQuery(this.gasStationTarget).on('select2:select', () => {
      this.fillImageDiscount()
      this.fillFreightCost()
    })

    jQuery(this.supplierTarget).on('select2:select', () => {
      this.fillFreightCost()
    })
  }

  fillImageDiscount(event) {
    const useDiscountImage = this.productUseDiscountImage()
    const flagType = this.selectedValue(this.flagTypeTarget)

    if (useDiscountImage && flagType === 'flagged') {
      this.setFlaggedImageDiscount()
    } else {
      this.imageDiscountTarget.value = '0'
    }
  }

  fillFreightCost(event) {
    const freightType = this.selectedValue(this.freightTypeTarget)

    switch (freightType) {
      case 'fob':
        this.freightCostTarget.value = ''
        this.freightCostTarget.removeAttribute('readonly')
        break

      case 'cif':
        this.freightCostTarget.value = ''
        this.freightCostTarget.setAttribute('readonly', true)
        break

      case 'own':
        this.freightCostTarget.setAttribute('readonly', true)
        this.setOwnFreightCost()
        break

      default:
        this.freightCostTarget.value = ''
        this.freightCostTarget.setAttribute('readonly', true)
    }
  }

  selectedOption(element) {
    return element.options[element.selectedIndex]
  }

  selectedValue(element) {
    return this.selectedOption(element).value
  }

  productUseDiscountImage() {
    let useDiscountImage = ''

    if (this.hasParent()) {
      useDiscountImage = this.productTarget.dataset.useDiscountImage
    } else {
      useDiscountImage = this.selectedOption(this.productTarget).dataset.useDiscountImage
    }

    return useDiscountImage === 'true'
  }

  gasStationId() {
    if (this.hasParent()) {
      return this.gasStationTarget.value;
    }

    return this.selectedValue(this.gasStationTarget)
  }

  supplierCityId() {
    if (this.hasParent()) {
      return this.supplierTarget.dataset.city
    }

    return this.selectedOption(this.supplierTarget).dataset.city
  }

  setFlaggedImageDiscount() {
    const gasStationId = this.gasStationId()
    if (!gasStationId) {
      return null;
    }

    const fetchData = async () => {
      const { json } = await requestApi(`/gas_stations/${this.gasStationId()}.json`)
      const imageDiscount = json.image_discount || 0

      this.imageDiscountTarget.value = numberWithPrecision(imageDiscount)
    }

    fetchData()
  }

  setOwnFreightCost() {
    const gasStationId = this.gasStationId()
    const supplierCityId = this.supplierCityId()

    if (!gasStationId || !supplierCityId) {
      return null;
    }

    const fetchData = async () => {
      const { status, json } = await requestApi(`/freights/cost_per_liter.json?gas_station_id=${gasStationId}&city_id=${supplierCityId}`)

      this.freightCostTarget.value = numberWithPrecision(json.cost_per_liter)
    }

    fetchData()
  }

  hasParent() {
    return this.parentTarget.value != ''
  }
}
